// actu dans la boucle de la liste ou widget
.actualite {

    $parent: &;

    &-actu {

        padding: 30px;
        &:hover {
            #{$parent}-img {
                opacity: .5;
            }
            /*
            #{$parent}-btn {
                @include hoverBouton;
            }*/
        }
    }

    &-link {
        color: $colorText;
    }

    &-title {
        margin-top: 0;
        font-size: 1.5rem;
    }

    &-title-link {
    }

    &-entry {
        font-size: rem($fzText * .9);
        display: block;
        padding-bottom: 15px;
    }

    &-date {

    }

    &-category {

    }

    &-content {
        border: 1px solid #f7f7f7;
        display: block;
        padding: 5px;
    }

    &-imgContainer {
        background-color: $primary;
        display: inline-block;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    &-img {
        transition: opacity .2s;
    }

    &-text {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    &-btn {
        /*@include bouton($hover: false);*/
    }
}
