//partials
@import './front/base/appfront';
@import './front/vendors/swiper';
@import './front/home/diaporama';

// si widget actu activé
@import './front/widgets/actu-widget';
@import './front/widgets/webimmo-widget';

// si widget galerie activé
@import './front/widgets/galerie-widget';

// si widget recrutement activé
@import './front/widgets/recrutement-widget';

// si widget catalogue activé
@import './front/widgets/catalogue-widget';

.texte-intro{margin:30px auto 70px;} 

.first{
    position: relative;
    background: url('/img/land-service-grenadier.jpg') no-repeat center center;
    background-size: cover;
    height: 800px;
    margin-bottom: 40px;
    &-titre{
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        width:50%;
        left: 1%;
        h1{
            color: #000;font-size: 30px;text-align: center;font-weight: 400;text-transform: inherit;
            span{color:#00152c;font-size: 60px;font-weight: 400;}
            strong{font-size: 80px;color:$secondary;font-weight: 700;display: block;}
            span, strong{text-transform:uppercase;}
            
        }
    }
    &-logo{
       position: absolute;
        bottom: 30px;
        width:50%;
        left: 1%;
        text-align: center;
        img{width: 370px;}
    }

}

.second{
    width: 100%;
    margin: auto;

    &-img{
        text-align: center;
        &-l{
            z-index: 10;
            width: 25%;
            max-width:530px;
            
        }
        &-c{
            margin-left:-150px;
            z-index: 20;
            width: 30%;
            max-width: 620px;
        }
        &-r{
            z-index: 20;
            width: 30%;
            max-width: 530px;
        }
        &-text{
            font-family: "Oswald", Arial, sans-serif;
            position: relative;
            z-index: 200;
            background: $secondary;
            max-width: 370px;
            width: 30%;
            min-height: 600px;
            padding: 20px 33px;
            margin-left:-100px;
            margin-right:-100px;
            color:#fff;
            font-size: 25px;
            text-transform: uppercase;
            text-align: left;
            line-height:40px;
            box-shadow: 0 0 30px rgba(0,0,0,0.3);
        
            strong{
                font-size: 60px;
                border-bottom: 1px solid #fff;
                display: block;
                padding-bottom: 20px;
                line-height: 80px;
                margin-bottom: 30px;
            }
            &-btn{
                position: absolute;
                left: 33px;
                bottom: 30px;
                a{width: 100%;}
            }
        }

    }
}

#configution_home{
    margin: 80px auto; 
}

.conf{
    margin-bottom:40px;
    &-img{
        position: relative;
        z-index: 10;
        width: 55%;
    }

    &-text{
        position: relative;
        z-index: 100;
        padding:30px 70px;
        background: #fff;
        width: 55%;
        font-size: 18px;
        h3{text-transform: inherit;color:$primary}
        strong{;font-weight: 700;}
        a{margin-top:40px;}
    }
}   
.conf-text.ctl{margin-left: -11%;border-right:5px solid $secondary}
.conf-text.ctr{margin-right: -11%;border-left:5px solid $secondary}
    

#motorisation_home{
    
}
.moteur{
    text-align: center;
    width: 400px;
    margin: 10px 5%;
    padding: 30px 50px 15px;
    background: #eeeeee;
}

#film_home{
    min-height: 300px;
    text-align: center;
    background: url('/img/bg-video.png') no-repeat center center;
    background-size: contain;
}

.video-home{
    width:30%;
    margin: auto;
    padding: 100px 0;
}
.video{
    left:0px;
    width: 100%;
    height: 0px;
    position: relative;
    padding-bottom: 75.0019%;
}

#actu_home{margin: 120px auto}

#entretien{
    background: $primary;
    color:#fff;    
}

.entretien-texte{
    color:#fff;
    padding:70px 90px;
    h2{color:#fff;font-size: 40px;width:600px;}
}

.entretien-img{
    background: url(/img/entretien.jpg) no-repeat center center;
    background-size: cover;
    min-height: 400px
}

@media(max-width:1400px){
    .first-titre h1{ font-size: 30px;}
    .first-titre h1 span{ font-size: 50px;}
    .first-titre h1 strong {  font-size:70px;}
    .second-img-l{width: 40%}
    .second-img-c{width: 50%;}
    .second-img-r{    width: 55%; margin-left: -150px;margin-top: -150px;}
    .second-img-text{margin: -150px 0 0 0 ;width: 370px;min-height: 450px;line-height: 30px;font-size: 20px;}
    .video-home { width: 60%;padding: 50px 0;}
    .entretien-texte h2{width: 100%;}
}


@media(max-width:1200px){
    .first-titre { width: 96%; left: 2%; }
    .first-titre h1{ background: rgba(188,192,201, 0.7); width: fit-content;  padding: 20px;text-align: left;}
    .conf-text h3 span{display: block} 
}

@media(max-width:991px){
    .conf-text{padding: 30px;}
    h2.big, h3.big {font-size:60px;}
    .entretien-texte h2{font-size:30px;}
    .second-img-l, .second-img-c {width: 45%;vertical-align: top;margin:0}
    .second-img-text {   margin: -50px auto 0;  width: 100%;}
    .second-img-r {  width: 96%;  margin: -20px auto -40px;}
    #configution_home {   margin: 80px auto 10px;}
    #actu_home {  margin: 50px auto;}
}


@media(max-width:767px){
    .first{height: 600px}
    .first-titre h1{ font-size: 20px;}
    .first-titre { padding: 10px 20px}
    .first-titre h1 span{ font-size: 40px;}
    .first-titre h1 strong {  font-size: 60px;}
    .conf.cr{display: flex; flex-wrap: wrap-reverse;}
    .conf-img{width: 100%;}
    .conf-text.ctl, .conf-text.ctr{width: 90%;}
    .conf-text.ctl, .conf-text.ctr{margin:-50px 5% 0 5%;}
    .entretien-texte { padding: 40px 30px;}
}


@media(max-width:580px){
    h2.big, h3.big { font-size:40px;}
    h2.big span, h3.big span{font-size: 18px;}
    .second-img-text{width: 100%;min-height: inherit;}
    .second-img-text strong { font-size: 40px;}
    .second-img-text-btn{bottom: -40px}
    .moteur{width: 100%;margin: 10px auto;}

}



@media(max-width:380px){
    .second-img-text {  margin: -10px auto 0;}
    .second-img-text strong {  font-size: 30px;line-height: 30px;}
}


















