// Body
$body-bg: #ffffff;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$light: #f8f9fa;
$dark: #343a40;
$gray: #d2d2d2;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$danger: $red;

// Les couleurs dominantes de la charte graphique peuvent être utilisées pour coloriser le texte avec TinyMCE, il suffit de recopier les héxadécimaux dans le fichier js\tinymce\tinymceConfig.js dans le tableau intitulé "textcolor_map"
$primary: #00152c;
$secondary: #b61615;
$colorDiscrete: $primary;

$bgcHeader: #fff;
$bgcFooter: #00152c;

$fzH1: 40px;
$fzH2: 32px;
$fzH3: 30px;
$fzH4: 24px;
$fzH5: 20px;
$fzH6: 16px;
$bx1: 0px 0px 10px rgba(0,0,0,0.3);
$bx2: 0px 0px 50px rgba(0,0,0,0.2);

$colorTitle: #00152c;
$colorText: #474747;
$colorLink: $primary;

$swiperThemeColor: $primary;

$fzText: 16px;

$ffTitle: "Oswald", Arial, sans-serif;
$ffText: "open_sans", Arial, sans-serif;
$ffItalic: "open_sans", Arial, sans-serif;
$ffBold: "open_sans", Arial, sans-serif;

$widthContainer: 1200px;

$mobile: '(max-width: 767px)';
$phone-portrait: '(max-width: 575px)';
$phone-landscape: '(min-width: 576px) and (max-width: 767px)';
$tablet-portrait: '(min-width: 768px) and (max-width: 991px)';
$tablet-landscape: '(min-width: 992px) and (max-width: 1199px)';
$large-desktop: '(min-width: 1200px) and (max-width: 1919px)';
$semi-desktop1: '(min-width: 1200px) and (max-width: 1439px)';
$semi-desktop2: '(min-width: 1440px) and (max-width: 1919px)';
$wide-desktop: '(min-width: 1920px)';
$non-retina: 'screen and (-webkit-max-device-pixel-ratio: 1)';
$retina: '(min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (-webkit-min-device-pixel-ratio: 1.5), (min-device-pixel-ratio: 1.5), (min-resolution: 144dpi), (min-resolution: 1.5dppx)';
