
.topBar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    line-height: 3em;
    background-color: $primary;
    .row{
        justify-content: flex-end;
        p, a{
            color:#fff;
            font-size: 14px;
            display: inline-block;
            vertical-align: middle;
            padding: 1px 0 2px;
            margin-bottom: 0;
            
            .iconfb{padding:0 10px;font-size:20px;vertical-align: middle;}
        }
    }
}

header {
    z-index: 1000;
    position: relative;
    background: $bgcHeader;
    padding: 20px 0;
    box-shadow: $bx1;
}

header .full-container{
    margin: auto;
    max-width: 1400px;
    
}

#logo {
  img {
    max-width: 100%;
    height: auto;
  }
}


a.link-site{margin-left:10px;text-decoration: underline;}

@media(max-width:1200px){
   .topBar .container, header .container{max-width: 100%}
    
}

@media(max-width:575px){
    .topBar{padding: 0 15px;}
    #logo{width: 100%!important;text-align: center;}
    
}