.top-footer{
        margin-top: 80px;
    padding: 10px;
    height: 50px;
    color:#fff;
    background: $secondary;
    
    &-left{width: 49.5%;border-right: 1px solid #fff;}
    &-right{width: 49.5%;;}
    .top-footer-tel{padding-left: 10%;}
    .w50{width: 49%;}
   
    .tpf14{font-size: 14px;}
    .tpf20, .tpf20 a{font-size: 20px;font-weight: 700;color:#fff;}
    .tpf16 a{font-size: 16px;font-weight: 700;color:#fff;}
    span.iconfoo{padding: 0 10px; font-size: 30px;vertical-align: middle}
}




footer {

  background: $bgcFooter;
  padding: 20px 0;
}

.info-foo{
    text-align: center;margin: auto;
    span, p{display: inline-block;color:#fff}
}

#logo-footer{display: block;margin:30px auto }
.border-top {
    border-top: 1px solid $secondary !important;
}
.border-top ul{margin: auto}
.border-top ul li, 
.border-top ul li a{display: inline-block;vertical-align: middle;color:#fff;padding: 0 10px}

@media(max-width:1400px){
    .top-footer{height: auto;}
    .iconfoo.icon-placeholder, .iconfoo.icon-phone-call{display: none;}
    .top-footer .top-footer-tel{padding: 0}
    .top-footer-left, .top-footer-right {  width: 100%;padding: 5px; border-right:none;}
    .top-footer-adr, .top-footer-tel, .top-footer-mail, .top-footer-fb {  width: 49%;  text-align: center;}
}

@media(max-width:767px){
    .top-footer-adr, .top-footer-tel, .top-footer-mail, .top-footer-fb {  width: 100%;  text-align: center;}
}

@media(max-width:480px){
   .top-footer .w50 {  width: 100%;}
}


