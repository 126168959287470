#menu {
  padding: 0;
}

.primaryMenu {
    justify-content: flex-end;
    
  &-list {
    margin-top: 0;
    margin-left: 0;
  }

  &-link {
    height: 100%;
    display: flex;
    align-items: center;

    text-align: right;
    color:$primary;
    font-size: 20px;
    font-family: "oswald";
  
  }
    
  li a{padding: 0.5rem 1.5rem !important;}
    
}

li#page-accueil{display: none;}



#navbarSupportedContent{
    justify-content: flex-end;
}


span.navbar-toggler-icon {
    background: $primary;
    display: block;
    height: 2px;
    margin: 5px auto;
    width: 25px;
}


.dropdown-item{max-width: 100%}

/* --------- Sous-Menu / open Bootstrap 4 dropdown on hover --------- */
/*
@media only screen and (min-width: 768px) {
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }
    
    
}*/
@media(max-width:767px){
   /* .primaryMenu-link{justify-content: end;}*/
    .navbar-nav{   align-items: flex-end;}
    
}