/* oswald-200 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 200;
  src: url('/fonts/oswald/oswald-v49-latin-200.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/oswald/oswald-v49-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/oswald/oswald-v49-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/oswald/oswald-v49-latin-200.woff') format('woff'), /* Modern Browsers */
       url('/fonts/oswald/oswald-v49-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/oswald/oswald-v49-latin-200.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-regular - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/oswald/oswald-v49-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/oswald/oswald-v49-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/oswald/oswald-v49-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/oswald/oswald-v49-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/fonts/oswald/oswald-v49-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/oswald/oswald-v49-latin-regular.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-600 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/oswald/oswald-v49-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/oswald/oswald-v49-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/oswald/oswald-v49-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/oswald/oswald-v49-latin-600.woff') format('woff'), /* Modern Browsers */
       url('/fonts/oswald/oswald-v49-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/oswald/oswald-v49-latin-600.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-700 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/oswald/oswald-v49-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/fonts/oswald/oswald-v49-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/oswald/oswald-v49-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/oswald/oswald-v49-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/fonts/oswald/oswald-v49-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/oswald/oswald-v49-latin-700.svg#Oswald') format('svg'); /* Legacy iOS */
}

    
@font-face {
  font-family: 'icomoon';
  src:  url('/fonts/icomoon/icomoon.eot?toxjqr');
  src:  url('/fonts/icomoon/icomoon.eot?toxjqr#iefix') format('embedded-opentype'),
    url('/fonts/icomoon/icomoon.ttf?toxjqr') format('truetype'),
    url('/fonts/icomoon/icomoon.woff?toxjqr') format('woff'),
    url('/fonts/icomoon/icomoon.svg?toxjqr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-facebook:before {
  content: "\e90f";
}
.icon-instagram:before {
  content: "\e910";
}
.icon-linkedin:before {
  content: "\e911";
}
.icon-speedometer:before {
  content: "\e90b";
}
.icon-Belstaff_logo:before {
  content: "\e90c";
}
.icon-calendar2:before {
  content: "\e90d";
}
.icon-gas-station:before {
  content: "\e90e";
}
.icon-avatar:before {
  content: "\e900";
}
.icon-envelope:before {
  content: "\e901";
}
.icon-facebook-app-symbol:before {
  content: "\e902";
}
.icon-next-1:before {
  content: "\e903";
}
.icon-phone-call:before {
  content: "\e904";
}
.icon-placeholder:before {
  content: "\e905";
}
.icon-play-button:before {
  content: "\e906";
}
.icon-settings-1:before {
  content: "\e907";
}
.icon-settings:before {
  content: "\e908";
}
.icon-star:before {
  content: "\e909";
}
.icon-tag:before {
  content: "\e90a";
}


@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-Regular-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-Regular-webfont.svg#open_sansregular') format('svg'),
         url('/fonts/OpenSans/OpenSans-Regular-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-Regular-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-BoldItalic-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-BoldItalic-webfont.svg#open_sansbold_italic') format('svg'),
         url('/fonts/OpenSans/OpenSans-BoldItalic-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-BoldItalic-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-Bold-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-Bold-webfont.svg#open_sansbold') format('svg'),
         url('/fonts/OpenSans/OpenSans-Bold-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-Bold-webfont.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-Semibold-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-Semibold-webfont.svg#open_sanssemibold') format('svg'),
         url('/fonts/OpenSans/OpenSans-Semibold-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-Semibold-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.svg#open_sanssemibold_italic') format('svg'),
         url('/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-SemiboldItalic-webfont.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-Italic-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-Italic-webfont.svg#open_sansitalic') format('svg'),
         url('/fonts/OpenSans/OpenSans-Italic-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-Italic-webfont.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-LightItalic-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-LightItalic-webfont.svg#open_sanslight_italic') format('svg'),
         url('/fonts/OpenSans/OpenSans-LightItalic-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-LightItalic-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'open_sans';
    src: url('/fonts/OpenSans/OpenSans-Light-webfont.eot');
    src: url('/fonts/OpenSans/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/OpenSans/OpenSans-Light-webfont.svg#open_sanslight') format('svg'),
         url('/fonts/OpenSans/OpenSans-Light-webfont.woff') format('woff'),
         url('/fonts/OpenSans/OpenSans-Light-webfont.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}


